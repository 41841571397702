import styled from 'styled-components';
import './App.css';
import { Col, Divider, Row } from 'antd';

function App() {
  return (
    <ContentStyled>
      <Divider>Fozito template web</Divider>

      <Row gutter={[20, 20]}>
        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <BoxTemplateStyled>
            <a href='https://site1.fozito.uk/' target='_blank'>
              <img src='/images/site-01.png' width={'100%'} />
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Site 01
              </span>
            </a>
          </BoxTemplateStyled>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <BoxTemplateStyled>
            <a href='https://site2.fozito.uk/' target='_blank'>
              <img src='/images/site-02.png' width={'100%'} />
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Site 02
              </span>
            </a>
          </BoxTemplateStyled>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <BoxTemplateStyled>
            <a href='https://citynails.us' target='_blank'>
              <img src='/images/site-03.png' width={'100%'} />
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Site 03
              </span>
            </a>
          </BoxTemplateStyled>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <BoxTemplateStyled>
            <a href='https://gardenianails.com' target='_blank'>
              <img src='/images/site-04.png' width={'100%'} />
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Site 04
              </span>
            </a>
          </BoxTemplateStyled>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <BoxTemplateStyled>
            <a href='https://noirenailbar.net' target='_blank'>
              <img src='/images/site-05.png' width={'100%'} />
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Site 05
              </span>
            </a>
          </BoxTemplateStyled>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <BoxTemplateStyled>
            <a href='https://qtnails.us/' target='_blank'>
              <img src='/images/site-06.png' width={'100%'} />
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Site 06
              </span>
            </a>
          </BoxTemplateStyled>
        </Col>
      </Row>
    </ContentStyled>
  );
}
export default App;

const ContentStyled = styled.div`
    padding: 15px;      
    
    && .ant-divider-with-text {
      font-size: 26px;
    }
`;

const BoxTemplateStyled = styled.div`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    text-align: center;
`;
